@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

html {
  min-height: 100vh;
  /* overflow: hidden; */
  background-color: #070707;
}

body {
  margin: 0;
  min-height: 100vh;
  background: no-repeat top url("secret-site-background.jpg");
  background-size: auto 100vh;
}

.connect-div {
  padding-top: 0vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 80px;
  height: 58vh;
}

.prompt-div {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
  /* margin-bottom: 50px; */
}

.journey {
  padding-top: 5px;
  max-width: 95vw;
  animation-duration: 1s;
  animation-name: fadein;
}

p {
  margin: 0px;
  margin-bottom: 10px;
}

a {
  color: #f3ff00;
}

.enter-input {
  width: 200px;
  background-color: #070707;
  border: none;
  height: 42px;
  padding-left: 10px;
  box-shadow: 0px 0px 15px 2px #B900FF;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
  transition-duration: 0.1s;
}

.enter-input:hover {
  background-color: #404040;
}

.key-button {
  background-color: #070707;
  border: none;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  height: 44px;
  box-shadow: 0px 0px 15px 2px #B900FF;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition-duration: 0.1s;
}

.key-button:hover {
  background-color: #404040;
}

.update-button {
  background-color: #070707;
  border: none;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 3px #B900FF;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition-duration: 0.1s;
}

.update-button:hover {
  background-color: #404040;
}

@media only screen and (max-width: 400px) {
  .prompt-div {
    font-size: 14px;
  }

  .update-button {
    font-size: 14px;
    padding: 10px;
  }
}